<template>
  <div class="default-reservation-modal">
    <b-modal v-model="visible" v-if="visible" v-on="$listeners" size="lg" hide-header-close
             header-class="justify-content-center" @ok.prevent="submitModal">

      <template #modal-title>
        <h1>{{ hasEditMode ? $t('reservations.edit') : $t('reservations.show') }}</h1>
      </template>

      <template #default="{ ok }">
        <b-form ref="form" @submit.prevent="ok(record)">
          <ul class="list-group">
            <li v-if="record.transfer.external_order_number || showEditExternalOrderNumber" class="list-group-item">
              <b-row>
                <b-col>
                  <div v-if="!showEditExternalOrderNumber">
                    <h5>{{ $t("common.order_number") }}</h5>
                    {{ record.transfer.external_order_number }}
                  </div>
                  <generic-input v-else v-model="record.transfer.external_order_number" label="common.order_number"
                                 placeholder="ORDER XXX"/>
                </b-col>
              </b-row>
            </li>
            <li v-if="record.transfer.external_invoice_number || showEditExternalInvoiceNumber" class="list-group-item">
              <b-row>
                <b-col>
                  <div v-if="!showEditExternalInvoiceNumber">
                    <h5>{{ $t("common.invoice_number") }}</h5>
                    {{ record.transfer.external_invoice_number }}
                  </div>
                  <generic-input v-else v-model="record.transfer.external_invoice_number" label="common.invoice_number"
                                 placeholder="INVOICE XXX"/>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("common.start_city") }}</h5>
                  <h4>
                    {{ record.transfer.start_shop.contact.name || record.transfer.start_shop.role_s }}<br>
                    <small>
                      {{ record.transfer.start_shop.street_name }}<br>
                      {{ record.transfer.start_shop.postcode }}, {{ record.transfer.start_shop.city.name }}
                      <div v-if="record.transfer.start_shop.contact.phone_number">
                        <font-awesome-icon icon="phone"/>
                        {{ record.transfer.start_shop.contact.phone_number }}
                      </div>
                    </small>
                  </h4>
                  <small>
                    <a class="" :href="record.transfer.route_link" target="_blank">
                      <font-awesome-icon icon="map-marker-alt"/>
                      {{ $t("transfers.on_a_map") }}
                    </a>
                  </small>
                </b-col>
                <b-col>
                  <h5>{{ $t("common.end_city") }}</h5>
                  <h4>
                    {{ record.transfer.end_shop.contact.name || record.transfer.end_shop.role_s }}<br>
                    <small>
                      {{ record.transfer.end_shop.street_name }}<br>
                      {{ record.transfer.end_shop.postcode }}, {{ record.transfer.end_shop.city.name }}
                      <div v-if="record.transfer.end_shop.contact.phone_number">
                        <font-awesome-icon icon="phone"/>
                        {{ record.transfer.end_shop.contact.phone_number }}
                      </div>
                    </small>
                  </h4>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("multiples.vehicle.one") }}</h5>
                  {{ record.transfer.vehicle.name }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.engine") }}</h5>
                  {{ record.transfer.engine_description }}
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <div v-if="!showEditVin">
                    <h5>{{ $t("common.vin") }}</h5>
                    {{ record.transfer.vin }}
                  </div>
                  <generic-input v-else v-model="record.transfer.vin" label="common.vin"
                                 placeholder="ZFAEFAC39MX018432"/>
                </b-col>
                <b-col>
                  <div v-if="!showEditLicensePlate">
                    <h5>{{ $t("common.license_plate") }}</h5>
                    {{ record.transfer.license_plate }}
                  </div>
                  <generic-input v-else v-model="record.transfer.license_plate" label="common.license_plate"
                                 placeholder="AAA-BB 123"/>
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col v-if="showMoverName">
                  <h5>{{ $t("multiples.driver.one") }}</h5>
                  {{ record.mover.name }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.pickup_day") }}</h5>
                  {{ record.date_range | formatDateRangeDay }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.pickup_time") }}</h5>
                  {{ record.date_range | formatDateRangeTime }}
                </b-col>
              </b-row>
            </li>
            <li v-if="showVehicleClass" class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("multiples.vehicle_class.one") }}</h5>
                  {{ record.transfer.vehicle_class.name }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.retention") }}</h5>
                  {{ record.transfer.vehicle_class.retention | formatPrice }}{{ $t('unit.euro') }}
                </b-col>
                <b-col>
                  <h5>{{ $t("common.deposit") }}</h5>
                  {{ record.transfer.vehicle_class.deposit | formatPrice }}{{ $t('unit.euro') }}
                </b-col>
              </b-row>
            </li>
            <li class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("common.distance") }}</h5>
                  {{ record.transfer.distance | formatDistance }} {{ $t('unit.km') }}
                  <span v-if="record.protocol_distance>0">
                    ({{ $t('common.recorded') }}: {{ record.protocol_distance | formatDistance }} {{ $t('unit.km') }})
                  </span>
                </b-col>
                <b-col>
                  <h5>{{ $t("transfers.duration") }}</h5>
                  {{ record.transfer.duration | formatDurationHours }} {{ $t('unit.hr') }}
                  {{ record.transfer.duration | formatDurationMinutes }} {{ $t('unit.min') }}
                </b-col>
                <b-col>
                  <h5> {{ $t("common.price") }}</h5>
                  {{
                    (price_source === 'transfer' ? record.transfer.price : record.price) | formatPrice
                  }}{{ $t('unit.euro') }}
                </b-col>
              </b-row>
            </li>
            <li v-if="showDriverPrice" class="list-group-item">
              <b-row>
                <b-col>
                  <h5>{{ $t("common.driver_price") }}</h5>
                  {{ record.driver_price | formatPrice }}{{ $t('unit.euro') }}
                </b-col>
              </b-row>
            </li>
            <li v-if="record.transfer.hint" class="list-group-item">
              <h5>{{ $t("common.hint") }}</h5>
              <div v-html="$simpleFormat(record.transfer.hint)"></div>
            </li>
            <li class="list-group-item" v-if="showNotes && record.notes">
              {{ $t("common.notes") }}
              <h5>{{ record.notes }}</h5>
            </li>
            <li class="list-group-item" v-if="showMetadata">
              <metadata-list v-model="record.transfer"/>
            </li>

            <li class="list-group-item" v-if="showEditNotes">
              <generic-input v-model="record.notes" label="common.notes" :placeholder="$t('transfers.additional_notes')"
                             @keyup.enter="submitModal(record)"/>
            </li>
            <li class="list-group-item" v-if="showEditDriver">
              <b-row>
                <b-col>
                  <generic-input type="select"
                                 v-model="record.driver_id"
                                 label="selector.select_driver"
                                 placeholder="selector.select_no_driver"
                                 :fetchRecords="fetchDrivers"/>
                </b-col>
                <b-col v-if="showDriverPrice && record.driver_id > 0" cols="4">
                  <generic-input class="float-right"
                                 type="currency"
                                 v-model="record.driver_price"
                                 required
                                 label="common.driver_price"/>
                </b-col>
              </b-row>
              <b-row v-if="recordOriginalDriverId > 0 && recordOriginalDriverId !== record.driver_id">
                <b-col>
                  <small class="text-danger">{{ $t('reservations.driver_change_warning') }}</small>
                </b-col>
              </b-row>
            </li>

            <metadata-edit-list v-if="showEditMetadata" v-model="record.transfer.metadata"
                                label="multiples.metadata.one"/>
          </ul>
        </b-form>
      </template>

      <template #modal-footer="{ cancel }">
        <div class="float-right">
          <b-button size="sm"
                    variant="default"
                    @click="cancel">
            {{ $t('button.back') }}
          </b-button>
          <b-button v-if="hasEditMode" size="sm"
                    variant="primary"
                    type="submit"
                    @click.prevent="$refs.form.requestSubmit()">
            {{ $t('button.save') }}
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
import {getSelectableDrivers} from '@/services/drivers';
import {getReservation, putReservation} from "@/services/reservations";
import MetadataList from "@/components/metadata_list";
import SearchableSelect from "@/components/searchable_select";
import GenericInput from "@/components/generic_input";
import MetadataEditList from "@/components/metadata_edit_list";

export default {
  name: "DefaultReservationModal",
  components: {MetadataEditList, GenericInput, SearchableSelect, MetadataList},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: -1,
    },
    showMoverName: {
      type: [Boolean, Function],
      default: true
    },
    showVehicleClass: {
      type: Boolean,
      default: false
    },
    showMetadata: {
      type: Boolean,
      default: false
    },
    showEditVin: {
      type: Boolean,
      default: false
    },
    showEditLicensePlate: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: false
    },
    showEditNotes: {
      type: Boolean,
      default: false
    },
    showEditMetadata: {
      type: Boolean,
      default: false
    },
    showEditDriver: {
      type: Boolean,
      default: false
    },
    showEditExternalOrderNumber: {
      type: Boolean,
      default: false
    },
    showEditExternalInvoiceNumber: {
      type: Boolean,
      default: false
    },
    price_source: {
      type: String,
      default: 'transfer'
    },
    showDriverPrice: {
      type: [Boolean, Function],
      default: false
    }
  },
  data() {
    return {
      visible: false,
      record: {},
      recordOriginalDriverId: 0
    }
  },
  watch: {
    value: {
      immediate: true,
      async handler() {
        if (this.value && this.id > 0) this.record = await getReservation(this.id) // TODO: catch
        this.recordOriginalDriverId = this.record?.driver_id || 0
        this.visible = this.value
      }
    },
    visible: {
      immediate: true,
      handler() {
        this.$emit('input', this.visible)
      }
    }
  },
  methods: {
    async submitModal() {
      putReservation(this.record.id, this.record).then((response) => {
        this.visible = false
      })
    },
    fetchDrivers(filterQuery) {
      return getSelectableDrivers(filterQuery)
    }
  },
  computed: {
    hasEditMode() {
      return this.showEditVin || this.showEditLicensePlate || this.showEditNotes || this.showEditMetadata || this.showEditDriver || this.showEditExternalOrderNumber || this.showEditExternalInvoiceNumber
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
<template>
  <div class="metadata-edit-list">

    <ul class="list-group m-b-sm" v-if="value.length > 0">
      <li v-for="(metadata, index) in value" class="list-group-item">
        <b-row>
          <b-col v-if="label">
            <h4>
              <font-awesome-icon v-if="icon" :icon="icon"/>
              {{ $t(label) + " " + (index + 1) }}
            </h4>
          </b-col>
          <b-col>
            <b-button v-if="!metadata.id" @click.prevent="$delete(value, index)" variant="no-outline"
                      style="float: right;">
              <font-awesome-icon icon="times"/>
            </b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <generic-input :required="true" label="common.name" v-model="metadata.name" :disabled="disabled"/>
          </b-col>
          <b-col>
            <generic-input :required="true" label="common.value" v-model="metadata.value" :disabled="disabled"/>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <generic-input v-if="metadata.id" label="button.remove" v-model="metadata._destroy"
                           type="checkbox" :disabled="disabled"/>
          </b-col>
        </b-row>
      </li>
    </ul>

    <b-row v-if="!$compute(disabled)" class="justify-content-center">
      <b-button @click.prevent="value.push(metadataDefaultState())" variant="default">
        {{ $t("metadata.add") }}
      </b-button>
    </b-row>

  </div>
</template>

<script>
import GenericInput from "@/components/generic_input";

export default {
  name: "MetadataEditList",
  components: {GenericInput},
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    disabled: {
      type: [Boolean, Function],
      default: false
    },
  },
  methods: {
    metadataDefaultState() {
      return {name: "", value: "", _destroy: false}
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
